@import 'styles/utilities.scss';
.wrapper {
  background-color: white;
}

.isolate {
  isolation: isolate;
}

.mainContent {
  min-height: 100vh;
  // background-color: #efefef;
}
