@import 'styles/utilities.scss';
/* // :root {
//   --header-height: 100px;
// } */

.header {
  position: sticky;
  // position: fixed;

  @include desktop {
    position: sticky;
  }

  top: 0;
  left: 0;
  width: 100%;
  min-height: var(--header-min-height);
  padding-bottom: var(--spacing-normal);
  z-index: 1000;
  -webkit-transition:
    top 0.5s,
    background-color 0.5s,
    opacity 0.5s;
  transition:
    top 0.5s,
    background-color 0.5s,
    opacity 0.5s;
  // mix-blend-mode: soft-light;
  mix-blend-mode: normal;
  background-color: overlay;
  opacity: 1;
}

.header:hover {
  top: 0 !important;
  mix-blend-mode: hard-light;
  background-color: none;
  opacity: 1;
}

.inner {
  --wrapper-padding: var(--spacing-unit-2) var(--page-padding-x);
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  height: 100%;
}

.logo {
  display: block;
  width: 120px;
}
