@import 'styles/utilities.scss';
.menu {
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-x-small);

  @include tablet {
    margin-top: var(--spacing-xx-small);
    margin-bottom: var(--spacing-x-small);
  }
}

.filtersContainer {
  ul {
    margin: 0;
  }
}
