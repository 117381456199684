@import 'styles/utilities.scss';
// :root {
//   --button-height: calc(var(--button-height) * 1.2);
// }
.btn {
  height: var(--button-height);
  font-size: var(--button-font-size);

  // --button-radius: calc(var(--button-height) / 2);
  --button-padding: calc(calc(var(--button-height) / 4)) calc(var(--button-height) / 2.5) calc(var(--button-height) / 4)
    calc(var(--button-height) / 2.5);

  // --button-padding: calc(
  //     calc(var(--button-height) / 4 - calc(var(--button-height) / 15))
  //   )
  //   calc(var(--button-height) / 2.5) calc(var(--button-height) / 4)
  //   calc(var(--button-height) / 2.5);

  --button-background: transparent;
  --button-background-active: transparent;
  --button-foreground-active: var(--color-button-text);
  // margin-right: var(--spacing-normal);

  span {
    margin-left: var(--spacing-normal);
  }

  text-transform: uppercase;
}

.icon {
  width: calc(var(--button-height) / 2);
  height: calc(var(--button-height) / 2);
}

.transparent {
  background-color: transparent;
}

.black {
  background-color: var(--color-text);
  border: 2px solid var(--color-text);
  color: var(--color-bg);
  &:hover {
    background-color: var(--color-bg);
    color: var(--color-text);
    border: 2px solid var(--color-text);
    path {
      stroke: var(--color-purple) !important;
    }
  }
}

.purple {
  background-color: var(--color-purple);
  border: 1px solid var(--color-purple);
  &:hover {
    background-color: transparent;
    color: var(--color-purple);
    border: 1px solid var(--color-purple);
    path {
      stroke: var(--color-purple) !important;
    }
  }
}

.grey {
  background-color: var(--color-grey);
  border: 1px solid var(--color-grey);
  &:hover {
    background-color: transparent;
    color: var(--color-grey);
    border: 1px solid var(--color-grey);
    path {
      stroke: var(--color-grey) !important;
    }
  }
}

.salmon {
  background-color: var(--color-salmon);
  border: 1px solid var(--color-salmon);
  &:hover {
    background-color: transparent;
    color: var(--color-salmon);
    border: 1px solid var(--color-salmon);
    path {
      stroke: var(--color-salmon) !important;
    }
  }
}

.green {
  background-color: var(--color-green);
  border: 1px solid var(--color-green);
  &:hover {
    background-color: transparent;
    color: var(--color-green);
    border: 1px solid var(--color-green);
    path {
      stroke: var(--color-green) !important;
    }
  }
}
.greenoutline {
  background-color: var(--color-green);
  border: 1px solid var(--color-bg);
  &:hover {
    background-color: var(--color-bg);
    color: var(--color-green);
    border: 1px solid var(--color-green);
    path {
      stroke: var(--color-green) !important;
    }
  }
}
.greeninverse {
  background-color: transparent;
  color: var(--color-green);
  border: 1px solid var(--color-green);
  path {
    stroke: var(--color-green) !important;
  }
  &:hover {
    background-color: var(--color-green);
    color: var(--color-bg);
    border: 1px solid var(--color-bg);
    path {
      stroke: var(--color-bg) !important;
    }
  }
}

.border {
  border: 1px solid var(--color-bg);
}

.invertpeach {
  background-color: var(--color-bg);
  color: var(--color-peach);
  path {
    stroke: var(--color-peach) !important;
  }
  &.border {
    border: 1px solid var(--color-peach);
  }
}

.dark {
  background-color: var(--color-bg);
  color: var(--color-blue);
}
