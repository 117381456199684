@import 'styles/utilities.scss';
.loader {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--design-size-6) var(--design-size-6) 0 var(--design-size-6);
  margin: var(--design-size-4) 0;
  @include tablet {
    margin: var(--design-size-6) 0 var(--design-size-8);
    padding: 0 var(--design-size-6) 0 var(--design-size-3);
  }
}
