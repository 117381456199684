@import 'styles/utilities.scss';
.isPortrait {
  max-width: 500px;
  margin: 0 auto;
}

.isMediaPortrait {
  text-align: center;
}

.text {
  font-size: var(--font-size-x-small);
  line-height: 21px;

  @include tablet {
    width: 100%;
    padding-right: var(--page-padding-x);
    .full & {
      width: 75%;
    }
  }

  p {
    font-size: var(--font-size-small);
  }
}

.sanityBlock {
  max-width: var(--content-max-inner-width);
  margin: 0 auto;
  font-size: var(--font-size-small);

  @include tablet {
    padding-right: var(--page-padding-x);
  }

  p a {
    text-decoration: underline;
  }
}
