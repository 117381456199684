@import 'styles/utilities.scss';
:root {
  --font-size-x-small: 14px;
  --font-size-small: 14px;
  --font-size-normal: 16px;
  --font-size-x-normal: 16px;
  --font-size-medium: 16px;
  --font-size-x-medium: 18px;
  --font-size-large: 18px;
  --font-size-x-large: 24px;
  --font-size-xx-large: 32px;
  --font-size-xxx-large: 76px;

  --font-family-body: "neue-haas-grotesk-text", sans-serif;
  --font-weight-normal: 400;
  --font-weight-x-normal: 500;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-extrabold: 900;
}

/* 768px */

@media screen and (min-width: 48em) {
  :root {
    --font-size-x-small: 14px;
    --font-size-small: 16px;
    --font-size-normal: 16px;
    --font-size-x-normal: 16px;
    --font-size-medium: 18px;
    --font-size-x-medium: 24px;
    --font-size-large: 24px;
    --font-size-x-large: 42px;
    --font-size-xx-large: 64px;
    --font-size-xxx-large: 72px;
  }
}

/* 960px */

@media screen and (min-width: 60em) {
  :root {
    --font-size-x-small: 14px;
    --font-size-small: 16px;
    --font-size-normal: 16px;
    --font-size-x-normal: 18px;
    --font-size-medium: 21px;
    --font-size-x-medium: 25px;
    --font-size-large: 28px;
    --font-size-x-large: 32px;
    --font-size-xx-large: 72px;
    --font-size-xxx-large: 84px;
  }
}

/* 1200px */

@media screen and (min-width: 75em) {
  :root {
    --font-size-x-small: 14px;
    --font-size-small: 16px;
    --font-size-normal: 18px;
    --font-size-x-normal: 21px;
    --font-size-medium: 24px;
    --font-size-x-medium: 25px;
    --font-size-large: 28px;
    --font-size-x-large: 36px;
    --font-size-xx-large: 89px;
    --font-size-xxx-large: 92px;
  }
}
