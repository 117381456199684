@import 'styles/utilities.scss';
.mainWrapper {
  margin-bottom: var(--spacing-x-large);
}

.infoGrid {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: var(--spacing-x-normal);
  @include tablet {
    grid-template-columns: 1fr 1fr;
    column-gap: var(--spacing-x-normal);
  }
}

.buyInstructions {
  margin-top: var(--spacing-x-normal);
}
