@import 'styles/utilities.scss';
.mailingList {
  margin: 0 var(--spacing-normal);
  @include tablet {
    margin: auto;
    display: flex;
    justify-content: center;
  }

  > div {
    border: var(--ns-border-width) solid var(--color-border);
    padding: var(--spacing-x-normal);
  }
}

.homepageBlocks {
  margin-bottom: var(--spacing-xx-large);
}

.homeMail {
  > div,
  > div > div > div {
    display: flex;
    flex-direction: column;

    @include tablet {
      min-width: 380px;
      max-width: 380px;
    }

    input {
      width: 100%;
    }

    button {
      font-size: var(--font-size-x-large);
      font-weight: var(--font-weight-bold);
      margin-top: var(--spacing-x-normal);
      text-align: left;
    }
  }

  > div > div {
    width: 100%;
    margin-top: var(--spacing-x-normal);
    position: relative;
  }
  label {
    font-size: var(--font-size-x-large);
    font-weight: var(--font-weight-medium);
  }
}
