@import 'styles/utilities.scss';
.relatedCardInside {
  margin: 0 var(--spacing-normal);
  background-color: var(--color-card-bg);
  @include desktop {
    margin: 0;
  }

  padding: var(--spacing-xx-normal);
  border: 1px solid #dedede;

  p {
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-x-normal);
  }

  a {
    text-decoration: none;

    h2:hover,
    h2:focus {
      text-decoration: underline;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.darkMode {
  border: none;
  color: var(--color-darkmode-text);
  background-color: var(--color-bg-dark);

  a {
    color: var(--color-darkmode-text);
  }

  &.backgroundPattern {
    background-color: var(--color-bg-dark);
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23333333' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

.backgroundPattern {
  border: none;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23cdcdcd' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.cardType {
  margin-bottom: var(--spacing-x-normal);
  text-transform: uppercase;
}

.cardLabel {
  margin-top: var(--spacing-normal);
}
