@import 'styles/utilities.scss';
.logo {
  max-width: 200px;
}

.supporters {
  display: flex;
  > div {
    img {
      width: 100%;
      height: auto;
    }

    width: 150px;
    margin-right: var(--spacing-x-normal);
    font-size: var(--font-size-x-small);

    p {
      margin: var(--spacing-x-small) 0;
    }
  }
}
