@import 'styles/utilities.scss';
.support {
  margin-bottom: var(--spacing-large);

  h3 {
    text-transform: uppercase;
  }
}

.mainWrapper {
  margin-bottom: var(--spacing-x-large);
}

.publicationGrid {
  @include tablet {
    display: grid;
    column-gap: var(--spacing-x-normal);
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.publicationBlocks {
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}
