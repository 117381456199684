@import 'styles/utilities.scss';
.support {
  margin-bottom: var(--spacing-large);

  h3 {
    text-transform: uppercase;
  }
}

.exhibitionHead {
  @include desktop {
    max-width: var(--content-max-inner-width);
    margin: 0 auto;
  }
}

.exhibitionText {
  font-size: var(--font-size-small);
  margin-bottom: var(--spacing-normal);
  a {
    text-decoration: underline;
  }
}

.pageBlocksWrapper {
  margin-top: var(--spacing-x-normal);
  @include desktop {
    margin-top: var(--spacing-xx-normal);
  }
}
