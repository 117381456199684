@import 'styles/utilities.scss';
.support {
  margin-bottom: var(--spacing-large);

  h3 {
    text-transform: uppercase;
  }
}

.featureImage {
  > div {
    width: 100%;
  }
}

.iframeContainer {
  height: 80vh;
  margin: 0;
  margin-bottom: var(--spacing-x-large);
}

.altHead {
  @include desktop {
    max-width: var(--content-max-inner-width);
    margin: 0 auto;
  }
}
