@import 'styles/utilities.scss';
@import "~normalize.css";
@import "./variables.scss";
@import "./utilities.scss";

* {
  box-sizing: border-box;
  // padding: 0;
  // margin: 0;
}

:root {
  --button-height: 54px;
  @include tablet {
    --button-height: 54px;
  }
  --button-font-size: calc(var(--button-height) / 2.2);
}

html,
body {
  font-family: var(--font-family-body);
  font-size: var(--font-size-normal);
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100vh;
  padding-bottom: var(--spacing-xx-normal);
}

html {
  background: var(--color-bg);
  min-height: 100%;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

a {
  color: var(--color-text);
  text-decoration: none;
}

a.active,
li.active,
span.active {
  color: var(--color-on);
  opacity: 1;
}

.role-button {
  cursor: pointer;
}

iframe {
  width: 100%;
  height: 100%;
}

.no-color {
  color: var(--no-color);

  &.active,
  &.inactive:focus {
    color: var(--no-color);
  }
}

form .msg-alert {
  top: 120%;
  left: 0;
  position: absolute;
}

p,
li {
  line-height: 1.5em;
  a:hover,
  a:focus {
    text-decoration: underline;
  }
}

h1,
h2,
h3 {
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 900;
}

h1 {
  font-size: var(--font-size-x-large);
  font-weight: var(--font-weight-bold);
  line-height: 38px;
  font-family: "neue-haas-grotesk-display", sans-serif;
}

.on {
  /* --color-bg: #ff0000; */

  h1,
  h2,
  .header2 {
    font-family: var(--imposed-font);
    font-size: 80px;
    font-weight: 400;
    max-width: 100%;
    overflow-wrap: break-word;

    @media screen and (min-width: 60em) {
      font-size: 110px;
    }
  }

  #navbar a,
  #navbar .onOff {
    @media screen and (min-width: 60em) {
      font-family: var(--imposed-font);
      font-size: 120px;
      font-weight: 400;
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }

  #navbar a.onOff {
    font-family: var(--imposed-font);
    font-size: 80px;
    @media screen and (min-width: 60em) {
      font-size: 120px;
    }
  }

  .noColor.active {
    color: red;
  }

  #navMobile {
    /* max-height: auto; */

    > div {
      margin-top: -20px;
    }
  }

  .cardInfo {
    max-width: 60%;
  }
}

.label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
}

.date {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-x-normal);
}

h2 {
  font-weight: var(--font-weight-bold);
  margin: var(--spacing-x-small) 0 var(--spacing-x-small) 0;
  font-size: var(--font-size-x-large);
  line-height: 1.2em;
}

a.active,
li.active,
span.active {
  color: var(--color-on);
}

address {
  font-style: normal;
}
