@import 'styles/utilities.scss';
.btn {
  display: flex;
  padding: var(--spacing-x-small) var(--spacing-small);
  border-radius: var(--button-radius);
  border: 0;

  // background-color: var(--button-background, var(--color-background));
  color: var(--button-foreground, var(--color-button-text));
  padding: var(--button-padding, var(--spacing-unit-2) var(--spacing-unit-3));
  // font-size: var(--button-font-size, inherit);

  // &.border {
  //   border: 1px solid var(--color-bg);
  // }

  &:not([disabled]) {
    cursor: pointer;
  }

  &:hover,
  &:focus-visible {
    // background-color: var(--button-background-active, var(--color-text));
    // color: var(--button-foreground-active, var(--color-background));
    color: var(--color-button-hover);
  }

  @include tablet {
    // box-shadow: 0 0 0px 0.6px currentColor;
  }
}

a.btn {
  text-decoration: none;
}
