@import 'styles/utilities.scss';
.support {
  margin-bottom: var(--spacing-large);

  h3 {
    text-transform: uppercase;
  }
}

.mainWrapper {
  margin-bottom: var(--spacing-x-large);
}
