@import 'styles/utilities.scss';
:root {
  --header-height: 48px;
  --header-min-height: 48px;
  --page-max-narrow-width: 788px;
  --page-max-width: 1024px;
  --content-max-width: 950px;
  --content-max-inner-width: 788px;
  --page-padding-x: var(--spacing-unit-3);
  --spacing-xx-small: var(--spacing-unit-1);
  --spacing-x-small: var(--spacing-unit-1);
  --spacing-small: var(--spacing-unit-2);
  --spacing-normal: var(--spacing-unit-3);
  --spacing-sx-normal: var(--spacing-unit-4);
  --spacing-x-normal: var(--spacing-unit-8);
  --spacing-xx-normal: var(--spacing-unit-10);
  --spacing-xxx-normal: var(--spacing-unit-12);
  --spacing-large: var(--spacing-unit-16);
  --spacing-mmx-large: var(--spacing-unit-18);
  --spacing-mx-large: var(--spacing-unit-23);
  --spacing-x-large: var(--spacing-unit-32);
  --spacing-xx-large: var(--spacing-unit-48);
  --ns-border-width: 3px;
  --menu-btn-padding-x: 5px;
  --wrapper-max-width: var(--page-max-width);
  --wrapper-max-narrow-width: var(--page-max-narrow-width);
  --wrapper-margin: 0 auto;
  --wrapper-padding: 0 var(--spacing-unit-2);
}

@media screen and (min-width: 48em) {
  :root {
    --header-height: 100px;
    --header-min-height: 48px;
    --page-max-width: 1200px;
    --content-max-width: 950px;
    --content-max-inner-width: 788px;
    --page-padding-x: var(--spacing-unit-6);
    --spacing-xx-small: var(--spacing-unit-1);
    --spacing-x-small: var(--spacing-unit-2);
    --spacing-small: var(--spacing-unit-3);
    --spacing-s-normal: 14px;
    --spacing-normal: var(--spacing-unit-4);
    --spacing-sx-normal: var(--spacing-unit-6);
    --spacing-x-normal: var(--spacing-unit-8);
    --spacing-xx-normal: var(--spacing-unit-10);
    --spacing-xxx-normal: var(--spacing-unit-12);
    --spacing-large: var(--spacing-unit-16);
    --spacing-mmx-large: var(--spacing-unit-20);
    --spacing-mx-large: var(--spacing-unit-22);
    --spacing-x-large: var(--spacing-unit-32);
    --spacing-xx-large: var(--spacing-unit-48);
    --ns-border-width: 3px;
    --menu-btn-padding-x: 5px;
  }
}
