@import 'styles/utilities.scss';
.block {
  position: relative;
  margin: 0 0 var(--spacing-x-normal);
}

.isPortrait {
  @include tablet {
    width: 75%;
    height: auto;
  }
}

.isEmbedPortrait {
  text-align: center;
}

.media {
  img {
    width: 100%;
    height: auto;
  }
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--spacing-unit-4);
  margin: 0 0 var(--spacing-x-small);

  iframe {
    max-height: 75vh;
  }
}

.maxHeight {
  max-height: 75vh;
}
