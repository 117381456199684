@import 'styles/utilities.scss';
.labelTitleDate {
  margin-bottom: var(--spacing-x-normal);

  p {
    margin: 0;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-x-normal);
    line-height: 28px;
    margin-bottom: var(--spacing-s-normal);
  }
}

.showTitle {
  margin-top: 0;
  margin-bottom: var(--spacing-s-normal);
  // font-weight: var(--font-weight-extrabold);
}

.label {
  text-transform: uppercase;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-x-small);
}

.extraInfo {
  margin-bottom: var(--spacing-x-small);
}

.dateExtraInfo {
  line-height: 1em;
}
