@import 'styles/utilities.scss';
.filtersWrapper {
  padding: 0 !important;
}

.archiveMenuItem {
  color: var(--color-inactive);
  opacity: 0.5;
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  padding-left: 0;
  padding-right: 0;
  margin-right: var(--spacing-normal);
  background: none;

  @include tablet {
    margin-right: var(--spacing-normal);
  }

  &:hover {
    color: var(--color-on);
    opacity: 1;
  }
}

.typeContainer {
  list-style: none;
  padding-left: 0;
  li {
    display: inline-block;
  }
  @include tablet {
    display: flex;
    flex-direction: row;
  }
  margin-top: var(--spacing-x-normal);
}

.filters {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-large);
  display: flex;

  > div {
    margin-right: var(--spacing-x-normal);
  }
}

.selector {
  width: 180px;
  > div {
    border-radius: 0;
    border: none;
  }

  [class*="placeholder"] {
    color: var(--color-text) !important;
  }

  &:focus,
  &:active {
    outline: none;
    border: none;
  }

  .control {
    border: none;
    box-shadow: none;
  }
}

.viewBy {
  margin-top: var(--spacing-x-normal);
  margin-bottom: var(--spacing-sx-normal);

  @include tablet {
    margin-top: var(--spacing-sx-normal);
    margin-bottom: var(--spacing-normal);
  }

  span {
    color: var(--color-inactive);
    opacity: 0.5;
    font-size: var(--font-size-x-normal);
    font-weight: var(--font-weight-medium);
    // margin-right: var(--spacing-normal);
    &:hover {
      color: var(--color-on);
      opacity: 1;
    }
    &:first-of-type {
      // margin-right: var(--spacing-x-normal);
    }
    &:focus {
      outline: none;
    }
  }
}

.active {
  color: var(--color-on);
  opacity: 1;
  &:hover {
    color: var(--color-on);
    opacity: 1;
  }
}
