@import 'styles/utilities.scss';
.blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--spacing-large);
}

.inviteCard {
  flex: none;
  width: 100%;

  @include desktop {
    width: 100%;
  }
}

.promoCard {
  flex: none;
  width: 100%;

  @include desktop {
    width: 40%;
  }
}
