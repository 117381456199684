@import 'styles/utilities.scss';
.bottomBorder {
  border-bottom: var(--ns-border-width) solid var(--color-text);
}

.topBorder {
  border-top: var(--ns-border-width) solid var(--color-text);
}

.menu {
  margin-bottom: var(--spacing-unit-8);

  @include tablet {
    margin-top: var(--spacing-xx-small);
    margin-bottom: var(--spacing-mmx-large);
  }
}

.shows {
  padding: 0 !important;
}
