@import 'styles/utilities.scss';
.wrapper {
  width: 100%;
  max-width: var(--wrapper-max-width);

  margin: var(--wrapper-margin);
  padding: var(--wrapper-padding);

  &Narrow {
    max-width: var(--wrapper-max-narrow-width);
  }
}
