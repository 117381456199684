@import 'styles/utilities.scss';
.container {
  --container-max-width: var(--page-max-width);
  --container-margin: 0 auto;
  --container-padding: 0 var(--page-padding-x);

  position: relative;

  width: 100%;
  // max-width: var(--container-max-width);

  margin: var(--container-margin);
  padding: var(--container-padding);

  border-bottom: var(--container-border-bottom);
}

.border {
  --container-border-bottom: var(--ns-border-width) solid var(--color-text);
}

.padded {
  --container-padding: 0 var(--page-padding-x);
}

.wrapped {
  max-width: none;
  margin: 0;
  padding: 0;

  .inner {
    position: relative;

    width: 100%;
    max-width: var(--container-max-width);

    margin: var(--container-margin);
    padding: var(--container-padding);
  }
}

.recent {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAACpJREFUKFNjZMAExgwMDGeRhRmxKMIQoq4iDDeg2WdMXevQfUNUEGAoAgBNywQ96O8CYAAAAABJRU5ErkJggg==)
    repeat;
  //   background-image: radial-gradient(currentColor 0.5px, transparent 0.5px),
  //     radial-gradient(currentColor 0.5px, transparent 0.5px);
  //   background-size: calc(20 * 0.5px) calc(20 * 0.5px);
  //   background-position:
  //     0 0,
  //     calc(10 * 0.5px) calc(10 * 0.5px);
  //
}
