@import 'styles/utilities.scss';
.navbar {
  width: 100%;
}

.hamBurger {
  display: none;
}

.mobilePadding {
  padding: var(--page-padding-x) var(--page-padding-x) 0 var(--page-padding-x);

  @include tablet {
    padding: calc(var(--spacing-normal) / 2) var(--spacing-normal) 0
      var(--spacing-normal);
  }
}

.navbarInner {
  display: flex;
  @include tablet {
    display: flex;
  }
  justify-content: space-between;
  a {
    text-decoration: none;
  }
}

.navbarItem {
  font-size: clamp(24px, 5vw, var(--font-size-xx-large));
  font-weight: var(--font-weight-medium);
  color: var(--color-inactive);
  opacity: 0.5;

  @include desktop {
    // font-size: var(--font-size-xx-large);
    font-weight: var(--font-weight-medium);
  }
  &:hover {
    // color: var(--color-hover);
    // opacity: 1;
  }

  // &:focus {
  //   // color: var(--color-text);
  // }
  // &.inactive:focus {
  //   color: var(--color-text);
  //   opacity: 1;
  // }
}

.menuBtn {
  display: block;
  float: right;
  position: relative;
  @include tablet {
    display: none;
  }

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;

  padding: 0;
  margin: 0 0 var(--spacing-unit-4);

  width: 48px;
  height: 48px;

  outline: 0;
  color: var(--color-text);

  button {
    border: none;
    background-color: transparent;
  }

  &Line {
    position: absolute;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    width: calc(100% - (var(--menu-btn-padding-x) * 2));
    height: 5px;

    left: var(--menu-btn-padding-x);

    background-color: var(--color-text);

    &:nth-child(1) {
      top: 14px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 27px;
    }

    &:nth-child(4) {
      top: 40px;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;

    .menu-btn-line {
      color: var(--color-item-bg);
    }
  }
}

.open {
  button {
    border: none;
    background-color: transparent;
  }

  span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
  }

  span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  span:nth-child(4) {
    top: 14px;
    width: 0%;
    left: 50%;
  }
}

.mobileMenu {
  overflow: hidden;
  display: block;
  display: none;

  &Inner {
    margin-top: var(--spacing-normal);
    margin-bottom: var(--spacing-x-normal);

    div {
      margin-bottom: var(--spacing-x-small);
    }
  }

  @include tablet {
    display: none;
  }

  a.navbar-item {
    &:hover,
    &:focus {
      color: var(--color-text);
      -webkit-transition: color 0.5s;
      transition: color 0.5s;
    }
  }
}

.indented {
  margin-left: var(--page-padding-x);
}
.mobileOpen {
  max-height: 400px;
  transition: max-height 0.5s ease-out;
}

.mobileClosed {
  max-height: 48px;
  transition: max-height 0.5s ease-out;
}

.skipContent {
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
  padding: 4px;
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s;
  z-index: 1000;

  &:focus {
    transform: translateY(270%);
  }
}

.onOff {
  &:hover {
    cursor: pointer;
  }
}
