@import 'styles/utilities.scss';
:root {
  --spacing-unit-1: 4px;
  --spacing-unit-2: 8px;
  --spacing-unit-3: 12px;
  --spacing-unit-4: 16px;
  --spacing-unit-5: 20px;
  --spacing-unit-6: 24px;
  --spacing-unit-7: 28px;
  --spacing-unit-8: 32px;
  --spacing-unit-9: 36px;
  --spacing-unit-10: 40px;
  --spacing-unit-11: 44px;
  --spacing-unit-12: 48px;
  --spacing-unit-13: 52px;
  --spacing-unit-14: 56px;
  --spacing-unit-15: 60px;
  --spacing-unit-16: 64px;
  --spacing-unit-17: 68px;
  --spacing-unit-18: 72px;
  --spacing-unit-19: 76px;
  --spacing-unit-20: 80px;
  --spacing-unit-21: 84px;
  --spacing-unit-22: 88px;
  --spacing-unit-23: 92px;
  --spacing-unit-24: 96px;
  --spacing-unit-25: 100px;
  --spacing-unit-26: 104px;
  --spacing-unit-27: 108px;
  --spacing-unit-28: 112px;
  --spacing-unit-29: 116px;
  --spacing-unit-30: 120px;
  --spacing-unit-31: 124px;
  --spacing-unit-32: 128px;
  --spacing-unit-33: 132px;
  --spacing-unit-34: 136px;
  --spacing-unit-35: 140px;
  --spacing-unit-36: 144px;
  --spacing-unit-37: 148px;
  --spacing-unit-38: 152px;
  --spacing-unit-39: 156px;
  --spacing-unit-40: 160px;
  --spacing-unit-41: 164px;
  --spacing-unit-42: 168px;
  --spacing-unit-43: 172px;
  --spacing-unit-44: 176px;
  --spacing-unit-45: 180px;
  --spacing-unit-46: 184px;
  --spacing-unit-47: 188px;
  --spacing-unit-48: 192px;
  --spacing-unit-49: 196px;
  --spacing-unit-50: 200px;
}
