@import 'styles/utilities.scss';
.relatedGrid {
  display: grid;
  width: 100%;

  @include tablet {
    grid-template-columns: 1fr 1fr;
  }

  > div {
    // padding-left: 30px; /* gutter size */
    background-clip: padding-box;

    > div {
      margin-bottom: var(--spacing-large);
    }
  }
}

.relatedCard {
  flex: none;
  width: 100%;

  @include desktop {
    padding-left: 35%;
    // width: 60%;
  }
}

.leftAlign {
  margin-left: 0;
}

.topOffset {
  margin-top: 20%;
}
