@import 'styles/utilities.scss';
:root {
  --color-text: #000;
  --color-inactive: #aaa;
  --color-darkmode-text: #fff;
  --color-border: #000;
  --color-bg: #fff;
  --color-card-bg: #fff;
  --color-image-bg: #f5f5f5;
  --color-on: #000;
  --color-over: #000066;
  --color-hover: #000000;
  --color-bg-dark: #000;
  --color-warning: #000;
  --no-color: #f0f0fd;

  /* All names kept ambiguous for possible accessibility palette switching */
  --color-palette-1: #f4eee3;
  --color-palette-2: #f800f9;
  --color-palette-3: #344a21;
  --color-palette-4: #582a5c;
  --color-palette-5: #387397;
  --color-item-1: var(--color-palette-3);
  --color-item-2: var(--color-palette-4);
  --color-item-3: var(--color-palette-5);
  --color-item-bg: #fff;
  --color-bg-stop-1: var(--color-palette-1);
  --color-bg-stop-2: var(--color-palette-2);
  /* --color-bg: linear-gradient(to bottom, var(--color-bg-stop-1), var(--color-bg-stop-2)); */
}
