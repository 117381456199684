@import 'styles/utilities.scss';
.icon {
  display: inline-block;

  flex-shrink: 0;

  width: var(--icon-size);
  height: var(--icon-size);

  line-height: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.xsmall {
  --icon-size: 14px;
}

.small {
  --icon-size: 14px;

  @include tablet {
    --icon-size: 18px;
  }
}

.normal {
  --icon-size: 14px;

  @include tablet {
    --icon-size: 20px;
  }
}

.medium {
  --icon-size: 18px;

  @include tablet {
    --icon-size: 32px;
  }
}

.large {
  --icon-size: 24px;

  @include tablet {
    --icon-size: 32px;
  }
}

.xlarge {
  --icon-size: 24px;

  @include tablet {
    --icon-size: 48px;
  }
}

.white-icon {
  stroke: #ffffff;
}
